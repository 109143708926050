import React, { useState, useEffect } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Grid from "@material-ui/core/Grid"
// import Button from '@material-ui/core/Button';
import { CircularProgress } from "@material-ui/core"
import ShowProperty from "../components/showproperty"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import { makeStyles } from "@material-ui/core/styles"
import ExpandMoreIcon from "@material-ui/icons/KeyboardArrowDown"
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight"
// import Helmet from "react-helmet"

const axios = require("axios")

let loader = (
  <div style={{ width: "100%", textAlign: "center", padding: "10vh 0 50vh 0" }}>
    <CircularProgress color="inherit" />
  </div>
)

const useStyles = makeStyles(theme => ({
  formControl: {
    color: "#FFFFFF",
    fontFamily: "AvenirMedium",
    fontSize: "0.8rem",
    fill: "red",
  },
}))

const Sales = () => {
  const pageHeader =
    "We have a wide variety of properties currently available, Have a browse, you may find your new home."
  const salesHeader = "Search for a property to buy"
  const buttonCopy = "SEARCH FOR MY NEW HOME"
  const [location, setLocation] = useState("")
  const [minprice, setMinPrice] = useState(50000)
  const [maxprice, setMaxPrice] = useState(500000)
  const [numBedrooms, setNumBedrooms] = useState(2)
  // const [order, setOrder] = useState('_price');
  const [order] = useState("_price")
  const [direction, setDirection] = useState("ASC")
  const [data, setData] = useState([])
  const [dataLoaded, setDataLoaded] = useState(true)
  // const [organiseDesc, setOrganiseDesc] = useState(true)

  const classes = useStyles()

  useEffect(() => {
    // make initial data call
    getProperty()
  }, [])

  // search function
  const getProperty = () => {
    setDataLoaded(false)

    const url = `https://db.brownandkay-ejfw.temp-dns.com/wp-json/custom/v2/search_properties?dept=residential-SALES&location=${location}&bedrooms=${numBedrooms}&min_price=${minprice}&max_price=${maxprice}&orderby=${order}&direction=${direction}`

    axios
      .get(url, {
        headers: {},
      })
      .then(function(response) {
        setDataLoaded(true)
        setData(response.data)
      })
      .catch(function(error) {
        console.log("error" + error)
      })
  }

  return (
    <Layout>
      <SEO title="Sales" />

      <Grid container>
        <Grid container className="grid6">
          <Grid container className="sales1">
            {pageHeader}
          </Grid>
          {/* end sales1 -- */}

          <Grid container className="sales_item_selector">
            <h2>{salesHeader}</h2>

            <Grid container className="sales_item_selector_search">
              <Grid item className="search_box">
                <label htmlFor="location">LOCATION: </label>
                <input
                  aria-label="location"
                  className="search_box_input"
                  id="locationInput"
                  value={location}
                  onChange={event => {
                    setLocation(event.target.value)
                  }}
                />
              </Grid>

              <Grid item className="search_box">
                <label htmlFor="minprice">MINIMUM PRICE:</label>

                <Select
                  IconComponent={ExpandMoreIcon}
                  className={classes.formControl}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={minprice}
                  onChange={event => {
                    setMinPrice(event.target.value)
                  }}
                >
                  <MenuItem value={50000}>£50,000</MenuItem>
                  <MenuItem value={100000}>£100,000</MenuItem>
                  <MenuItem value={150000}>£150,000</MenuItem>
                  <MenuItem value={200000}>£200,000</MenuItem>
                  <MenuItem value={250000}>£250,000</MenuItem>
                  <MenuItem value={300000}>£300,000</MenuItem>
                  <MenuItem value={350000}>£350,000</MenuItem>
                  <MenuItem value={400000}>£400,000</MenuItem>
                  <MenuItem value={450000}>£450,000</MenuItem>
                  <MenuItem value={500000}>£500,000</MenuItem>
                  <MenuItem value={550000}>£550,000</MenuItem>
                  <MenuItem value={600000}>£600,000</MenuItem>
                  <MenuItem value={650000}>£650,000</MenuItem>
                  <MenuItem value={700000}>£700,000</MenuItem>
                  <MenuItem value={750000}>£750,000</MenuItem>
                  <MenuItem value={800000}>£800,000</MenuItem>
                  <MenuItem value={850000}>£850,000</MenuItem>
                  <MenuItem value={900000}>£900,000</MenuItem>
                  <MenuItem value={950000}>£950,000</MenuItem>
                  <MenuItem value={1000000}>£1,000000</MenuItem>
                  <MenuItem value={1500000}>£1,500000</MenuItem>
                  <MenuItem value={2000000}>£2,000000</MenuItem>
                  <MenuItem value={2500000}>£2,500000</MenuItem>
                  <MenuItem value={3000000}>£3,000000</MenuItem>
                  <MenuItem value={3500000}>£3,500000</MenuItem>
                  <MenuItem value={4000000}>£4,000000</MenuItem>
                  <MenuItem value={4500000}>£4,500000</MenuItem>
                  <MenuItem value={5000000}>£5,000000</MenuItem>
                </Select>
              </Grid>

              <Grid item className="search_box search_organiser">
                <div>ORGANISE BY:</div>
                <div className="search_dropdown">
                  <Select
                    IconComponent={ExpandMoreIcon}
                    className={classes.formControl}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={direction}
                    onChange={event => {
                      setDirection(event.target.value)
                    }}
                  >
                    <MenuItem value={"ASC"}>Price Asc</MenuItem>
                    <MenuItem value={"DESC"}>Price Desc</MenuItem>
                  </Select>
                </div>
              </Grid>

              <Grid item className="search_box">
                <label className="search_box_label" htmlFor="minprice">
                  NO OF BEDROOMS:{" "}
                </label>

                <Select
                  IconComponent={ExpandMoreIcon}
                  className={classes.formControl}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={numBedrooms}
                  onChange={event => {
                    setNumBedrooms(event.target.value)
                  }}
                >
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5+</MenuItem>
                </Select>
              </Grid>

              <Grid item className="search_box">
                <label htmlFor="maxprice">MAXIMUM PRICE:</label>

                <Select
                  IconComponent={ExpandMoreIcon}
                  className={classes.formControl}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={maxprice}
                  onChange={event => {
                    setMaxPrice(event.target.value)
                  }}
                >
                  <MenuItem value={50000}>£50,000</MenuItem>
                  <MenuItem value={100000}>£100,000</MenuItem>
                  <MenuItem value={150000}>£150,000</MenuItem>
                  <MenuItem value={200000}>£200,000</MenuItem>
                  <MenuItem value={250000}>£250,000</MenuItem>
                  <MenuItem value={300000}>£300,000</MenuItem>
                  <MenuItem value={350000}>£350,000</MenuItem>
                  <MenuItem value={400000}>£400,000</MenuItem>
                  <MenuItem value={450000}>£450,000</MenuItem>
                  <MenuItem value={500000}>£500,000</MenuItem>
                  <MenuItem value={550000}>£550,000</MenuItem>
                  <MenuItem value={600000}>£600,000</MenuItem>
                  <MenuItem value={650000}>£650,000</MenuItem>
                  <MenuItem value={700000}>£700,000</MenuItem>
                  <MenuItem value={750000}>£750,000</MenuItem>
                  <MenuItem value={800000}>£800,000</MenuItem>
                  <MenuItem value={850000}>£850,000</MenuItem>
                  <MenuItem value={900000}>£900,000</MenuItem>
                  <MenuItem value={950000}>£950,000</MenuItem>
                  <MenuItem value={1000000}>£1,000000</MenuItem>
                  <MenuItem value={1500000}>£1,500000</MenuItem>
                  <MenuItem value={2000000}>£2,000000</MenuItem>
                  <MenuItem value={2500000}>£2,500000</MenuItem>
                  <MenuItem value={3000000}>£3,000000</MenuItem>
                  <MenuItem value={3500000}>£3,500000</MenuItem>
                  <MenuItem value={4000000}>£4,000000</MenuItem>
                  <MenuItem value={4500000}>£4,500000</MenuItem>
                  <MenuItem value={5000000}>£5,000000</MenuItem>
                </Select>
              </Grid>

              <Grid item className="search_box box_no_border">
                <div className="search_button" onClick={getProperty}>
                  <div>{buttonCopy}</div>
                  <div>
                    <KeyboardArrowRightIcon />
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
          {/* end sales selector -- */}

          {/* display data --------------------------------------------- */}
          <Grid container className="sales_data_holder">
            {dataLoaded ? (
              <ShowProperty data={data} next={getProperty} />
            ) : (
              loader
            )}
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default Sales
